import m0 from "views/cards";
import m1 from "views/editor";
import m2 from "views/folders";
import m3 from "views/list";
import m4 from "views/menus/addnewmenu";
import m5 from "views/menus/contextmenu";
import m6 from "views/menus/menubody";
import m7 from "views/mobile/previewpopup";
import m8 from "views/mobile/sidetree";
import m9 from "views/panel-double";
import m10 from "views/panel-search";
import m11 from "views/panel";
import m12 from "views/preview/index.js";
import m13 from "views/preview/info";
import m14 from "views/preview/media";
import m15 from "views/preview/template";
import m16 from "views/progress";
import m17 from "views/sections/dataview";
import m18 from "views/top";
import m19 from "views/topbar";
import { JetView } from "webix-jet";

const views = { JetView };
views["cards"] = m0;
views["editor"] = m1;
views["folders"] = m2;
views["list"] = m3;
views["menus/addnewmenu"] = m4;
views["menus/contextmenu"] = m5;
views["menus/menubody"] = m6;
views["mobile/previewpopup"] = m7;
views["mobile/sidetree"] = m8;
views["panel-double"] = m9;
views["panel-search"] = m10;
views["panel"] = m11;
views["preview"] = m12;
views["preview/info"] = m13;
views["preview/media"] = m14;
views["preview/template"] = m15;
views["progress"] = m16;
views["sections/dataview"] = m17;
views["top"] = m18;
views["topbar"] = m19;

export default views;
