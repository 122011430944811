export const folderIcon = "<span class='webix_icon wxi-folder'></span>";
export const backIcon =
	"<span class='webix_icon wxi-angle-double-left webix_fmanager_back_icon'></span>";
export const folderCardIcon =
	"<span class='webix_icon wxi-folder webix_fmanager_folder_icon'></span>";
export const menuIcon =
	"<span class='webix_icon wxi-dots webix_fmanager_menu_icon'></span>";

export const gridMode =
	"<span class='webix_fmanager_icon fmi-view-list webix_fmanager_mode_icon'></span>";
export const cardMode =
	"<span class='webix_fmanager_icon fmi-view-grid webix_fmanager_mode_icon'></span>";
export const doubleMode =
	"<span class='webix_fmanager_icon fmi-view-array webix_fmanager_mode_icon'></span>";
